import './App.css';
import {RedocStandalone} from 'redoc';
import React from "react";

function App() {
  return (
    <div>
      <RedocStandalone specUrl="/openapi.json"/>
    </div>
  );
}

export default App;
